import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/medical-sector.jpg');

const aboutCourierService1 = [
    {
        titlePart1: "Transporting medical supplies",
        titlePart2: "in a safe and secure way",
      
        description: "At M3 Couriers, we know how important it is for patients' health that medical goods are delivered safely and on time. Those are the reasons we've spent money on cutting-edge shipping options that will keep your packages safe. Your sensitive medical items, like vaccines, blood products, and medicines, will stay at the right temperature during travel, thanks to our temperature-controlled trucks. We put security measures at the top of our list to keep your expensive goods safe from theft, damage, or people who aren't supposed to be there. Our drivers undergo much training to ensure they handle medical goods carefully and follow tight rules to keep them from getting tampered with or contaminated. "
        ,image: require('.././../../images/6.JPG')
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "Medical Courier Services",
        titlePart2: "for Emergencies",
     
        image: require('.././../../images/7.jpg'),
        description: "Every second counts when things are very bad. That's why M3 Couriers offers emergency medical delivery services to get medical goods to people who need them quickly and easily. Our experienced drivers are available 24 hours a day, seven days a week, to react to pressing requests and ensure that life-saving drugs, testing tools, or necessary patient samples get to their destination as quickly as possible. When making unexpected deliveries, we know how important it is to maintain high accuracy and dependability. Our dispatch team works closely with healthcare providers to ensure all the necessary paperwork and information is for picks and transports. Also, our drivers have the newest contact tools to tell you how your product is doing in real time."
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Reliable medical",
        titlePart2: "courier services",
      
        image: require('.././../../images/medical-sector-aboutimage3.jpeg'),
        description: "At M3 Couriers, we believe that all healthcare workers, regardless of their size or scope, should have the opportunity to get the highest quality medical courier services. As a result of this, we provide you with a variety of options and pricing that are affordable to meet your requirements. The development of a cost-effective solution that is also reliable and of high quality is our goal. We are aware that each and every medical facility, whether it be a hospital or a clinic, has its own unique requirements. Whether you want supplies and pick-ups on a daily basis or only sometimes, we are able to tailor our services to meet your specific needs and financial constraints. Our highly trained personnel will collaborate with you to develop a one-of-a-kind solution that not only satisfies your requirements but also provides you with the highest possible value."
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Expertise and Experience:",
        description: "Our team has years of experience handling medical deliveries, ensuring that your critical shipments are handled with the utmost care."
    },
    {
        title: "Safety and Security:",
        description: "We prioritise the safety and security of your medical supplies. Our temperature-controlled vehicles and advanced tracking systems guarantee the integrity of your shipments."
    },
    {
        title: "Timeliness and Reliability:",
        description: "We understand the importance of timely healthcare delivery. Our dedicated team and efficient processes ensure that your shipments arrive on time every time."
    },
    {
        title: "Emergency Services:",
        description: "We offer 24/7 emergency courier services to meet your urgent medical needs. Our team is always ready to respond to critical situations and deliver time-sensitive shipments."
    },
    {
        title: "Experienced Team:",
        description: "Our professionals have years of experience in the shipping industry."
    },
    {
        title: "Customer Satisfaction:",
        description: "Our commitment to customer satisfaction drives everything we do. We strive to exceed your expectations and build long-lasting partnerships."
    }
];
class MedicalSectorTransport extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title="Medical Sector Transport" pagename="Medical Sector Transport" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    
                    <OurWorkingProcess />
                    <WhyChooseUs title= "Medical Sector Transport?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                                  
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default MedicalSectorTransport;