import React, { useState, useEffect, useRef } from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import { useForm } from '@formspree/react';
import GoogleMapReact from 'google-map-react';

var bnrimg = require('./../../images/banner/contact-us-banner-image.jpg');

const ContactUs = () => {
    const [state, handleSubmit] = useForm("mpwagwoy");
    const [formMessage, setFormMessage] = useState('');
    const formRef = useRef(null); // To reference the form fields

    // A flag to track form submissions.
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (state.succeeded && !isSubmitted) {
            setFormMessage('Form Submitted!');
            setIsSubmitted(true);

            // Clear form fields after submission
            if (formRef.current) {
                formRef.current.reset(); // Reset all fields in the form
            }

            // Reset the form state after showing the message.
            const resetTimer = setTimeout(() => {
                setIsSubmitted(false); // Reset form submission flag.
                setFormMessage(''); // Clear the message after a while.
            }, 3000); // Reset the form submission flag after 3 seconds.

            // Clear the timer if component unmounts or re-renders.
            return () => clearTimeout(resetTimer);
        }
    }, [state.succeeded, isSubmitted]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormMessage(''); // Reset message on each submit attempt.
        setIsSubmitted(false); // Reset submission flag before a new submission.
        await handleSubmit(e); // Handle the form submission.
    };

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Contact Us" pagename="Contact Us" bgimage={bnrimg} />
                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <form
                                        className="contact-form cons-contact-form"
                                        method="post"
                                        onSubmit={handleFormSubmit}
                                        ref={formRef} // Set the form reference
                                    >
                                        <div className="contact-one m-b30">
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one ">
                                                            <span className="font-weight-300 text-primary">Get</span> In touch
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input name="username" type="text" required className="form-control" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="text" className="form-control" required placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <input name="phone" type="tel"  pattern="\d*" className="form-control" required placeholder="Phone" />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" rows={4} className="form-control" required placeholder="Message" defaultValue={""} />
                                            </div>
                                            <div className="custom-flex ">
                                                <div style={{ minWidth: '200px' }}>
                                                    {formMessage ? <p>{formMessage}</p> : <p>&nbsp;</p>}
                                                </div>
                                                <button name="submit" type="submit" disabled={state.submitting} value="Submit" className="site-button btn-effect">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one ">
                                                        <span className="font-weight-300 text-primary">Contact</span> Info
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs">
                                                    <i className="fa fa-phone" />
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                    <p>
                                                        <a href="tel:07875 671065">07875 671065</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs">
                                                    <i className="fa fa-envelope" />
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Email address</h5>
                                                    <p>
                                                        <a href="mailto:paul@m3couriers.com">paul@m3couriers.com</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left">
                                                <div className="icon-xs">
                                                    <i className="fa fa-map-marker" />
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Address info</h5>
                                                    <p>Address: M3 Couriers Limited, 206 Fair Oak Road, Eastleigh, SO50 8HT</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gmap-outline">
                            <div style={{ height: '400px', width: '100%' }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.405210696053!2d-1.3428398840665267!3d50.96723437954696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4873eb067d8f48b5%3A0xfbc8fb9a29f14e5c!2sM3%20Couriers%20Limited%2C%20206%20Fair%20Oak%20Rd%2C%20Eastleigh%20SO50%208HT%2C%20UK!5e0!3m2!1sen!2suk!4v1691893078321!5m2!1sen!2suk"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    title="M3 Couriers Location"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
