import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-5.png');

class Specialization extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img className="custom-image-height" src={require('../../images/services/Pharmaceutical-courier-service.jpg')} alt=""/ >
                                        <div className="figcaption">
                                            <h4>M3 Couriers Limited
                                            </h4>
                                          
                                            <NavLink to="#" ><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img className="custom-image-height" src={require('../../images/services/banner1.jpg')} alt="" />
                                        <div className="figcaption">
                                            <h4>M3 Couriers Limited
                                            </h4>
                                          
                                            <NavLink to="#" ><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img className="custom-image-height" src={require('../../images/services/slider-image-1.webp')} alt="" />
                                        <div className="figcaption bg-dark">
                                            <h4>M3 Couriers Limited
                                            </h4>
                                         
                                            <NavLink to="#" ><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-6 m-b30">
                                    <div className="mt-box our-speciallization-content">
                                        <h3 className="m-t0">OUR FLEET.</h3>
                                        <p>Contact M3 Couriers for seamless and efficient delivery solutions. Our expertise combined with your needs is the perfect formula for success. </p>
                                        <NavLink to="/our-fleet"  className="site-button btn-effect">View All</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
                        <strong>Our Fleet</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization;