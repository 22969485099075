import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

var bgimage = require('./../../images/background/bg-site.png');

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = {logo: require('./../../images/Footer-logo.png')};
    }
    
    updateFooterLogo = (updatedlogo) => {this.currentstate.logo = updatedlogo;}

    render() {
        
        return (
            <>
                <footer className="site-footer footer-large  footer-dark footer-wide">
                    <div className="container call-to-action-wrap bg-no-repeat bg-center" style={{ backgroundImage: 'url('+bgimage+')' }}>
                        <div className="p-a30 bg-primary ">
                            <div className="row">
                                <div className="col-md-8 col-sm-8">
                                    <div className="call-to-action-left text-white">
                                        <h4 className="text-uppercase m-b10 m-t0">M3 Couriers Limited</h4>
                                        <span>Contact M3 Couriers for seamless and efficient delivery solutions.</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <div className="call-to-action-right text-end">
                                        <div className="widget_newsletter">
                                            <div className="newsletter-bx">
                                        
                                                <NavLink to={"/contact"}>
                                                    <button type="submit" className="site-button btn-effect ">
                                                    Contact Us
                                                    </button>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                            <NavLink to={"/m3couriers"}><img src={this.currentstate.logo} alt="" /></NavLink>
                                        </div>
                                        <p className="max-w400 p-t10">Thank you for choosing M3 Couriers as your trusted delivery partner we are committed to providing you with exceptional service and timely deliveries.
</p>
                                        <ul className="social-icons  mt-social-links">
                                            <li><NavLink to={"https://www.facebook.com/profile.php?id=100088332771753"} className="fa fa-facebook" /></li>
                                            <li><NavLink to={"https://www.pinterest.co.uk/MiaMeelia/m3-couriers/"} className="fa fa-pinterest" /></li>

                                        </ul>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Contact Us</h4>
                                        <ul className="widget_address">
                                            <li>Address: M3 Couriers Limited, 206 Fair Oak Road, Eastleigh, SO50 8HT</li>
                                            <li>
                                                <a href="mailto:paul@m3couriers.com">Email: paul@m3couriers.com</a>
                                            </li>
                                            <li> <a href="tel:07875 671065">Tel: 07875 671065</a></li>
                                           
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Services</h4>
                                        <ul>
                                            <li><NavLink to={"/same-day-courier"}>Same Day Courier</NavLink></li>
                                            <li><NavLink to={"/medical-sector-transport"}>Medical Sector Transport</NavLink></li>
                                            <li><NavLink to={"/overnight-courier"}>Overnight Courier</NavLink></li>
                                            <li><NavLink to={"/exhibition-transport-set-up"}>Exhibition Transport & Set up</NavLink></li>
                                            <li><NavLink to={"/regular-routes"}>Regular Routes</NavLink></li>
                                            <li><NavLink to={"/pharmaceutical"}>Pharmaceutical</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* TAGS */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget recent-posts-entry-date">
                                        <h4 className="widget-title">Location</h4>
                                        <div className="widget-post-bx">
                                            
                                        <div style={{ width: '265px', height: '270px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.405210696053!2d-1.3428398840665267!3d50.96723437954696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4873eb067d8f48b5%3A0xfbc8fb9a29f14e5c!2sM3%20Couriers%20Limited%2C%20206%20Fair%20Oak%20Rd%2C%20Eastleigh%20SO50%208HT%2C%20UK!5e0!3m2!1sen!2suk!4v1691893078321!5m2!1sen!2suk"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="M3 Couriers Location"
      ></iframe>
    </div>
                                        </div>
                                    </div>
                                </div>
                                {/* NEWSLETTER */}
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="mt-footer-bot-center">
                                    <span className="copyrights-text">© 2024 M3 Couriers Limited. Designed & Developed By <a href="https://wixdek.com/" className='text-white font-weight-600'>Wixdek</a>
                                    .</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)}/>

            </>
        );
    };
};

export default Footer;