import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class ServiceBenefits extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container service-benefits">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                              
                             
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer ">
                                <div className="mt-separator">
                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Benefits of Using</span >  M3 Couriers</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6 m-b30  text-white ">
                                    <ul className='font-20'>
                                        <li className='p-b60'><strong>Fast and Reliable Same-Day Delivery: </strong>  M3 Couriers delivers quickly and reliably. Our skilled drivers and powerful tracking technologies guarantee that your products arrive safely and promptly throughout the UK. </li>
                                        <li><strong>Trustworthy Handling:  </strong> We prioritise your deliveries. We carefully and securely handle your packages at M3 Couriers. Our personnel are trained to safeguard your belongings throughout delivery. You can rest easy knowing your valuables are safe with our secure handling and comprehensive tracking.</li>
                                   </ul>
                                </div>
                                <div className="col-md-6 col-sm-6 m-b30 text-white">
                                    <ul className='font-20'>
                                        <li className='p-b60'><strong>Competitive Pricing:  </strong>  M3 Couriers provides low prices without sacrificing quality. We Provide affordable, dependable, same-day delivery. Our price is flexible, so you can use our services without breaking the bank.</li>
                                        <li><strong>Customer Service:  </strong> M3 Couriers takes pleasure in offering excellent customer service. Our specialised staff is here to answer your questions and handle your problems. All of our clients should have a pleasant and easy experience.</li>
                                   </ul>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ServiceBenefits;