import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/same-day-courier.webp');

const aboutCourierService1 = [
    {
        titlePart1: "Fast, Reliable, and Secure",
        titlePart2: "Same-Day Delivery",
     
        image: require('.././../../images/4.jpg'),
        description: "At M3 Couriers, we understand the significance of timely and effective deliveries. Therefore, we are dedicated to providing an efficient, dependable, and protected same-day courier service that meets your expectations. Our team of skilled experts and modern facilities guarantee your products' careful handling and fast delivery. By using M3 Couriers, you may have confidence that your deliveries are securely handled, providing peace of mind. Our stringent protocols for handling and our advanced tracking system provide complete transparency about your shipments' status. Suppose you want prompt delivery of important papers, samples, or components. In that case, we possess the necessary knowledge and skills to manage these tasks effectively."
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "M3 Couriers:",
        titlePart2: "Delivering Results, Every Time",
      
        image: require('.././../../images/5.jpg'),
        description: "At M3 Couriers, we know how important it is to make packages on time and regularly. We aim to provide excellent same-day delivery services that go above and beyond what you expect. We always get results because we have a network of experienced drivers, the latest tracking technology, and a dedication to ensuring our customers are happy. Whether you need urgent papers, samples, or parts sent anywhere in the UK, our team is ready to handle your package carefully. To give you peace of mind, we offer a range of choices, reasonable prices, and real-time tracking. When you need same-day delivery that is quick, easy, and safe, M3 Couriers is the company to call."
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Fast and Effective",
        titlePart2: "Same-Day Delivery",
       
        image: require('.././../../images/same-day-courier-about-image3.jpg'),
        description: "We understand that sometimes you need items delivered promptly. For urgent goods, we provide same-day delivery, which is dependable and efficient. With same-day delivery, your shipments arrive on the same business day, giving you speed and convenience. Same-day deliveries are our top priority and are handled carefully. Our cutting-edge tracking solutions let you trace your cargo in real-time, giving you peace of mind. Same-day delivery from M3 Couriers is ideal for sending important papers, delicate products, or bulky boxes. We provide cheap pricing and various solutions to meet your needs. Our dedication to customer satisfaction makes shipping easy. Try M3 Couriers for same-day delivery and experience our quickness and dependability. You can trust us to deliver essential deliveries on schedule, every time."
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Unparalleled Speed:",
        description: "Experience lightning-fast delivery times with M3 Couriers. Our extensive network and efficient operations ensure your packages reach their destination swiftly, saving you valuable time and resources."
    },
    {
        title: "Reliability You Can Count On:",
        description: "M3 Couriers is committed to delivering on our promises. Our reliable services guarantee that your shipments arrive on time, every time, giving you peace of mind and enhancing your business efficiency."
    },
    {
        title: "Expert Handling and Care:",
        description: "Your packages are safe with M3 Couriers. Our experienced team handles each shipment carefully, ensuring they arrive in pristine condition, no matter how delicate or valuable."
    },
    {
        title: "Advanced Tracking Technology:",
        description: "Our real-time tracking system informs you about your shipment's progress. Monitor your delivery from pickup to delivery, ensuring complete visibility and control over your packages."
    }
];
class SameDayCourier extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title="Same-Day Delivery" pagename="Same Day Courier" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    <ServiceBenefits/>
                    <OurWorkingProcess />
                    <WhyChooseUs title= "Same-Day Delivery?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                    
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default SameDayCourier;