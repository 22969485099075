import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';


var img1 = require('./../../images/background/bg-1.1.jpg');

class OurValue2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
               
                    <div className="container">
                    
                        <div className="section-content">
                        
                            <div className="row">
                           
                                <div className="col-md-7 col-sm-6">
                                <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                                <div className="video-section-inner">
                                                   
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                <div className="section-head">
                                <div className="mt-separator-outer ">
                                    
                                    <div className="about-us mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Us</h2>
                                    </div>
                                </div>
                            </div>
                                    {/* TITLE START */}
                                    <div className="video-part-2">
                                    
                                        <h3><span className="font-weight-100">Years of Experience,</span> Delivering Excellence</h3>
                                        <p>M3 Couriers has many years of industry expertise. It has a strong reputation for exceptional courier services. Our focus on safe, on-time deliveries has made us the top choice for businesses and individuals. We know your shipments are important. We ensure each delivery is handled with care. We are committed to guaranteeing your contentment and delivering outstanding customer support.</p>
                                        <NavLink to={"/about"}>
                                        <button type="submit" className="site-button btn-effect ">
                                            About Us
                                        </button>
                                        </NavLink>
                                                 
                                               
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/34741214' />
                        </div>
                    </div>
                </div>
                <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>About Us</strong>
                    </div>
            </>
        );
    }
};

export default OurValue2;