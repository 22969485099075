import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('/assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
            <div className="section-full p-t80 about-summary  bg-white p-b50">
                <div className="container">
                        <div className="section-content ">
                             {/* TITLE START */}
                             <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">

                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Us</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        <div className="m-service-containt text-black">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="service-about-left">
                                        <div className="mt-media">
                                            <img src={require('./../../images/14.JPG')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                

                                        <div className="service-about-right m-b30">
                                        <h3 className="m-t0">
            <span style={{ color: '#003092', fontWeight:300,fontSize:38 }}>M3 Couriers:</span> 
            <span style={{ color: 'black' , fontWeight:300,fontSize:38 }}> Your Trusted Partner for Reliable Delivery Solutions</span>
         </h3>

                <p>M3 Couriers is a leading courier service provider committed to delivering excellence in every shipment. With a focus on reliability, efficiency, and customer satisfaction, we offer a wide range of services tailored to meet your diverse needs. From same-day deliveries and overnight shipping to specialised solutions for the medical sector, exhibition logistics, and pharmaceutical transportation, M3 Couriers is your go-to partner for all your courier requirements. Our extensive network, experienced team, and commitment to quality ensure that your packages reach their destination safely, securely, and on time.</p>
            </div>
                
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


           
        </>
        );
    }
};

export default AboutSummary;