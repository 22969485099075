import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/pharmaceutical.jpg');

const aboutCourierService1 = [
    {
        image: require('.././../../images/pharmaceutical-service-image2.jpeg'),
       
        titlePart1: "Safe and Secure Transportation",
        titlePart2: "for Your Pharmaceuticals",
        description: "At M3 Couriers, we understand the critical importance of transporting pharmaceuticals with the utmost care. Our dedicated team is committed to providing safe and secure delivery solutions that meet all requirements of the pharmaceutical industry. We employ state-of-the-art transportation methods and temperature-controlled vehicles to ensure the integrity of your medications. Our fleet is equipped with advanced tracking systems, allowing us to monitor the location and condition of your shipments in real time. Your pharmaceuticals are handled with the utmost care throughout the transportation process. We adhere to strict quality control measures and follow industry best practices to minimize the risk of contamination or damage. "
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "Your Partner for",
        titlePart2: "Reliable Pharmaceutical Logistics",
        image: require('.././../../images/pharmaceutical-service-image1.jpg'),
       
        description: "When it comes to the transportation of pharmaceuticals, reliability is paramount. M3 Couriers is your trusted partner for delivering critical medications on time and in pristine condition. Our team of experienced professionals understands the unique challenges associated with pharmaceutical logistics. We are committed to providing a seamless and efficient service that meets industry standards. We offer comprehensive services tailored to your specific needs, including expedited delivery, temperature-controlled transportation, and regulatory compliance. We aim to simplify the logistics process, allowing you to focus on your core business. Experience the M3 Couriers difference. Trust us to be your partner for reliable and efficient pharmaceutical logistics."
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Regulatory Standards",
        titlePart2: "for Pharmaceutical Shipping",
        image: require('.././../../images/pharmaceutical-service-about-image3.jpg'),
       
        description: "Compliance with regulatory standards is essential in the pharmaceutical industry. We are committed to adhering to the highest quality and safety standards in our pharmaceutical shipping services. We are well-versed in the regulations governing the transportation of pharmaceuticals, including Good Distribution Practices (GDP) and other relevant guidelines. Our team is trained to ensure that these standards handle all shipments. We implement robust quality control measures to monitor and maintain the integrity of your pharmaceuticals throughout the transportation process. Our facilities and vehicles are regularly inspected and maintained to meet the required standards. By choosing us, you will confident that your pharmaceuticals are being transported in compliance with all relevant regulations."
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Expertise and Experience:",
        description: "Our team has years of experience handling pharmaceutical logistics, ensuring that your medications are transported with the utmost care and professionalism."
    },
    {
        title: "Temperature-Controlled Transportation:",
        description: "We utilize state-of-the-art vehicles to maintain the required temperature conditions for sensitive pharmaceuticals."
    },
    {
        title: "Regulatory Compliance:",
        description: "M3 Couriers is committed to adhering to industry standards and regulations, ensuring your medications' safe and compliant transportation."
    },
    {
        title: "Safety and Security:",
        description: "Your pharmaceuticals are handled with the utmost care and security, protected from contamination, damage, and unauthorized access."
    },
    {
        title: "Reliability and Efficiency:",
        description: "Count on M3 Couriers for timely and efficient delivery of your critical medications."
    },
    {
        title: "Personalized Service:",
        description: "We offer customized solutions to meet your needs, including expedited delivery and emergency services."
    },
    {
        title: "Customer Satisfaction:",
        description: "Our goal is to provide exceptional customer service and ensure your complete satisfaction with our pharmaceutical shipping services."
    }
];
class Pharmaceutical extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title="Pharmaceutical" pagename="Pharmaceutical" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    
                    <OurWorkingProcess />
                    <WhyChooseUs title= "Pharmaceutical Shipping Needs?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                                  
                <Footer />
                    
                </div>

            
            </>
        );
    };
};

export default Pharmaceutical;