import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: 'Rooms & Halls',
        flaticon: 'flaticon-city',
        description: 'lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.',
    },
    {
        count: 2,
        title: 'Renovation',
        flaticon: 'flaticon-paint',
        description: 'lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.',
    },
    {
        count: 3,
        title: 'Construction',
        flaticon: 'flaticon-crane',
        description: 'lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.',
    },
    {
        count: 4,
        title: 'Interior',
        flaticon: 'flaticon-decorating',
        description: 'lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.',
    },
    {
        count: 5,
        title: 'Professional Opinion',
        flaticon: 'flaticon-chart',
        description: 'lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.',
    },
    {
        count: 6,
        title: 'Accurate Engineering',
        flaticon: 'flaticon-sketch',
        description: 'lorem Ipsum available, but the majority have suffered alterati on in some form, by ious by accident.',
    },
    
]

var img1 = require('./../../images/background/bg-5.png');
var img2 = require('./../../images/background/line.png');

class WhyChooseUs extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('/assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
              <div className="section-full p-t30   mobile-page-padding" >
                    <div className="container" id='whychoose-us-container'>
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                              
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Why Choose Us</span> <span className='text-black'>for {this.props.title }</span>  </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-6 m-b30 text-white ">
                                <ul className='font-20 text-black'>
                                        {this.props.points.map((point, index) => (
                                            <li key={index} className='p-b30'>
                                                <strong>{point.title} </strong>
                                                {point.description}
                                            </li>
                                        ))}
                                </ul>
                                    
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WhyChooseUs;