import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-1.1.jpg');

class Callus extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-center" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="overlay-main bg-primary opacity-01" />
                    <div className="container">
                        <div className="section-content">
                            <div className="call-us-section text-center text-white">
                                <h2 className="m-b15 text-uppercase">Let's work together</h2>
                                {/* <h2 className="call-us-number m-b15 m-b0">(+291)-456-789-1234</h2> */}
                                <p className="call-us-address m-t0 m-b20 ">Contact M3 Couriers for seamless and efficient delivery solutions. Our expertise combined with your needs is the perfect formula for success. Whether it's same-day deliveries, medical transport, or regular routes, we've got you covered. Let's build a lasting partnership based on trust, reliability, and exceptional service. Contact us today to discuss your delivery requirements</p>
                                <a className="font-22 site-button-secondry  btn-effect bg-dark" href="tel:07875 671065">07875 671065</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Callus;