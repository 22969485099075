import React, { useState, useEffect, useRef } from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import ContactUs from './ContactUs';
import AboutCourierService from '../Elements/AboutCourierService';
import { useForm } from '@formspree/react';

const img1 = require('./../../images/icon/Image1.png');
const img2 = require('./../../images/icon/Image10.png');
const img3 = require('./../../images/icon/Image9.png');
const img4 = require('./../../images/icon/Image7.png');
const img5 = require('./../../images/icon/Image8.png');
const img6 = require('./../../images/icon/Image3.png');
const img7 = require('./../../images/icon/Image5.png');
const img8 = require('./../../images/icon/Image4.png');
const img9 = require('./../../images/icon/Image2.png');
const img10 = require('./../../images/icon/Image6.png');
const bnrimg = require('./../../images/main-slider/slider1/3.jpg');

const aboutCourierService1 = [
    {
        titlePart1: "M3 Couriers:",
        titlePart2: "Our Fleet",
        image: require('./../../images/13.jpg'),
        description: "M3 Couriers is proud of our modern and well-maintained fleet of vehicles, which are essential to providing reliable and efficient courier services. Our fleet comprises a variety of vehicles, including vans, trucks, and motorcycles, all equipped with the latest technology to ensure safe and timely deliveries. We regularly service and maintain our vehicles to the highest standards, ensuring their optimal performance and reliability. We invest in cutting-edge tracking systems and communication devices to monitor our fleet in real-time, allowing us to optimise routes and respond quickly to any unexpected situations. With our diverse fleet, we are able to handle a wide range of shipments, from small packages to large freight. We design our vehicles to transport your goods securely and efficiently, ensuring they arrive at their destination on time and in excellent condition. Trust M3 Couriers to deliver your packages with our reliable and well-maintained fleet."
    },
];

const OurFleet = () => {
    const [state, handleSubmit] = useForm("mpwagwoy");
    const [formMessage, setFormMessage] = useState('');
    const formRef = useRef(null); // To reference the form fields

    // A flag to track form submissions.
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (state.succeeded && !isSubmitted) {
            setFormMessage('Form Submitted!');
            setIsSubmitted(true);

            // Clear form fields after submission
            if (formRef.current) {
                formRef.current.reset(); // Reset all fields in the form
            }

            // Reset the form state after showing the message.
            const resetTimer = setTimeout(() => {
                setIsSubmitted(false); // Reset form submission flag.
                setFormMessage(''); // Clear the message after a while.
            }, 3000); // Reset the form submission flag after 3 seconds.

            // Clear the timer if component unmounts or re-renders.
            return () => clearTimeout(resetTimer);
        }
    }, [state.succeeded, isSubmitted]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormMessage(''); // Reset message on each submit attempt.
        setIsSubmitted(false); // Reset submission flag before a new submission.
        await handleSubmit(e); // Handle the form submission.
    };


    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Our Fleet" pagename="Our Fleet" bgimage={bnrimg} />
                <AboutCourierService aboutservice1={aboutCourierService1} />
                <div className="clearfix our-fleet">
                    <div className="row">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our  </span>Fleets</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img1}
                                            className="card-img-top"
                                            alt="Motorbike"
                                        />
                                    </div>
                                    <h5 className="card-title">Motorbike</h5>
                                    <p className="card-text">
                                        Our agile motorbikes are perfect for navigating through congested traffic and delivering smaller packages 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img2}
                                            className="card-img-top"
                                            alt="Small Van"
                                        />
                                    </div>
                                    <h5 className="card-title">Small Van</h5>
                                    <p className="card-text">
                                        Our small vans are ideal for local deliveries and smaller shipments. They offer a compact and manoeuvrable solution
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img3}
                                            className="card-img-top"
                                            alt="ULEV"
                                        />
                                    </div>
                                    <h5 className="card-title">ULEV</h5>
                                    <p className="card-text">
                                        We dedicate our ULEV fleet to reducing our environmental impact while providing reliable and efficient delivery services.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img4}
                                            className="card-img-top"
                                            alt="SWB Van"
                                        />
                                    </div>
                                    <h5 className="card-title">SWB Van</h5>
                                    <p className="card-text">
                                        Our SWB vans are perfect for city deliveries and tight spaces. They offer manoeuvrability and efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img5}
                                            className="card-img-top"
                                            alt="MWB Van"
                                        />
                                    </div>
                                    <h5 className="card-title">MWB Van</h5>
                                    <p className="card-text">
                                        Our MWB vans offer a balance of space and manoeuvrability, making them versatile for a variety of delivery needs.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img6}
                                            className="card-img-top"
                                            alt="LWB Van"
                                        />
                                    </div>
                                    <h5 className="card-title">LWB Van</h5>
                                    <p className="card-text">
                                        Our LWB vans offer extra space for transporting larger shipments, making them ideal for longer distances.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img7}
                                            className="card-img-top"
                                            alt="XLWB Van"
                                        />
                                    </div>
                                    <h5 className="card-title">XLWB VAN</h5>
                                    <p className="card-text">
                                        Our XLWB vans can accommodate larger shipments, making them ideal for transporting bulky items.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img8}
                                            className="card-img-top"
                                            alt="Luton Van"
                                        />
                                    </div>
                                    <h5 className="card-title">LUTON VAN</h5>
                                    <p className="card-text">
                                        Our Luton vans, with their distinctive box-shaped body, provide ample space for transporting larger items.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img9}
                                            className="card-img-top"
                                            alt="7.5-Tonne Van"
                                        />
                                    </div>
                                    <h5 className="card-title">7.5-Tonne Van</h5>
                                    <p className="card-text">
                                        Our 7.5-tonne vans are ideal for transporting heavier loads, offering a reliable and efficient solution for your delivery needs.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 animated fadeIn card-container">
                            <div className="card">
                                <div className="card-body">
                                    <div className="avatar">
                                        <img
                                            src={img10}
                                            className="card-img-top"
                                            alt="18 Tonne Lorry"
                                        />
                                    </div>
                                    <h5 className="card-title">18 TONNE LORRY</h5>
                                    <p className="card-text">
                                        For heavy-duty transportation and large-scale deliveries, our 18-tonne lorries are the perfect solution.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                 {/* SECTION CONTENT START */}
                 <div className="section-full p-tb80 inner-page-padding">
                    {/* LOCATION BLOCK*/}
                    <div className="container">
                <div className="section-content">
                            {/* CONTACT FORM */}
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <form className="contact-form cons-contact-form" method="post"    onSubmit={handleFormSubmit}
                                        ref={formRef} >
                                        <div className="contact-one m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="form-group">
                                                <input name="username" type="text" required className="form-control" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="text" className="form-control" required placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <input name="phone" type="tel" pattern="\d*" className="form-control" required placeholder="Phone" />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" rows={4} className="form-control " required placeholder="Message" defaultValue={""} />
                                            </div>
                                            <div className="custom-flex ">
                                                <div style={{ minWidth: '200px' }}>
                                                    {formMessage ? <p>{formMessage}</p> : <p>&nbsp;</p>}
                                                </div>
                                                <button name="submit" type="submit" disabled={state.submitting} value="Submit" className="site-button btn-effect">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        {/* TITLE START */}
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                </div>
                                            </div>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                    <p><a href="tel:07875 671065">07875 671065</a></p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Email address</h5>
                                                    <p><a href="mailto:paul@m3couriers.com">paul@m3couriers.com</a></p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left">
                                                <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Address info</h5>
                                                    <p>Address: M3 Couriers Limited, 206 Fair Oak Road, Eastleigh, SO50 8HT</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>   
                    </div>   
            </div>
            <Footer />
        </>
    );
};

export default OurFleet;
