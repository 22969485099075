import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/Google-Reviews1.png'),
        reviewername: 'Shelly Johnson',
        position: 'Client',
        review: 'M3 Couriers has been an invaluable partner for our business. Their Same Day Courier service has consistently exceeded our expectations.'
    },
    {
        image: require('./../../images/testimonials/Google-Reviews1.png'),
        reviewername: 'Cuthbert Brain',
        position: 'Client',
        review: 'We use M3 Couriers for our medical supply. Their commitment to timely and secure delivery is unmatched. We have complete confidence in their ability to handle our sensitive cargo with care.'
    },
    {
        image: require('./../../images/testimonials/Google-Reviews1.png'),
        reviewername: 'Cathrine Wagner',
        position: 'Client',
        review: 'M3 Couriers made our exhibition setup a breese. Their exhibition transport and setup service saved us countless hours of stress. The team was efficient and professional throughout the entire process.'
    },
    {
        image: require('./../../images/testimonials/Google-Reviews1.png'),
        reviewername: 'John Doe',
        position: 'Client',
        review: "We've been using M3 Couriers for our regular routes for over a year now, and we couldn't be happier with their service. Their drivers are punctual and reliable, and the online tracking system is fantastic."
    },
    {
        image: require('./../../images/testimonials/Google-Reviews1.png'),
        reviewername: 'Cuthbert Brain',
        position: 'Client',
        review: 'M3 Couriers has been a game-changer for our pharmaceutical deliveries. Their adherence to strict regulations and commitment to timely delivery is impressive. We highly recommend their services.'
    }
]

var bnr1 = require('./../../images/background/bg6.jpg');

class Testimonials extends React.Component {
    render() {

        const options = {
            loop:true,
            autoplay:true,
            margin:30,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                991:{
                    items:2
                }
            }
        };

        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Client</span> Testimonials</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL START */}
                            <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                            
                                {testimonials.map((item, index) => (
                                    <div key={index} className="item">
                                        <div className="testimonial-2 m-a30 hover-animation-1">
                                            <div className=" block-shadow bg-white p-a30">
                                                <div className="testimonial-detail clearfix">
                                                    <div className="testimonial-pic radius shadow scale-in-center"><img src={item.image} width={100} height={100} alt=""/></div>
                                                    <h4 className="testimonial-name m-b5">{item.reviewername} -</h4>
                                                    <span className="testimonial-position">{item.position}</span>
                                                </div>
                                                <div className="testimonial-text">
                                                    <span className="fa fa-quote-right" />
                                                    <p> {item.review}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>    
                               
                            
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Clients</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials;