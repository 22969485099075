import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const logos = [
    { image: require('./../../images/gallery/3.jpg') },
    { image: require('./../../images/gallery/5.jpg') },
    { image: require('./../../images/gallery/7.jpg') },
    { image: require('./../../images/gallery/12.jpg') },
    { image: require('./../../images/gallery/13.jpg') },
    { image: require('./../../images/gallery/20.jpg') },
    { image: require('./../../images/gallery/30.JPG') },
    { image: require('./../../images/gallery/22.jpg') },
    { image: require('./../../images/gallery/25.jpg') },
    { image: require('./../../images/gallery/18.jpg') },
  
];


class Gallery extends React.Component {
    render() {
        const options = {
            loop:true,
            margin:0,
            autoplay:true,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                480:{
                    items:1
                },			
                767:{
                    items:2
                },
                1000:{
                    items:3
                }
            }
        };
        return (
            <>
                <div className="section-full p-t10 bg-white p-b50">
                    <div className="container">
                        <div className="section-content">
                             {/* TITLE START */}
                             <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Gallery</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL 4 START ON BACKGROUND */}
                            <div className="section-content">
                            <OwlCarousel className="owl-theme" {...options}>
            {logos.map((logo, index) => (
                <div className="item  margin-right-left-10px" key={index}>
                    <img  src={logo.image} alt={`Slide ${index + 1}`}  style={{ width: '600px', height: '400px', objectFit: 'cover' }} />
                </div>
            ))}
        </OwlCarousel>
                            </div>
                        </div>
                    </div>

                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Gallery</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Gallery;