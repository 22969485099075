import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCourierService2 extends React.Component {
    render() {
        return (
            <>
        


                <div className="section-full p-t80 bg-white p-b80 about-courier-service-section2">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                  
                                    <div className="col-md-6 col-sm-12">
                                       
{this.props.aboutservice2.map((item, index) => (
                <div key={index} className="service-about-right m-b30">
        <h3 className="m-t0">
        <span style={{ color: '#003092', fontWeight:300,fontSize:38 }}>{item.titlePart1}</span> 
        <span style={{ color: 'black' , fontWeight:300,fontSize:38 }}> {item.titlePart2}</span>
                    </h3>
                    <p>{item.description}</p>
                </div>
            ))}            
                                        
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <div className="service-about-left">
                                            {/* <div className="mt-media">
                                                <img src={require('./../../images/same-day-excellence-service.jpg')} alt=""/>
                                            </div> */}
                                            {this.props.aboutservice2.map((item, index) => (
                                <div key={index} className="mt-media">
                                    <img src={item.image} alt={item.title} />
                                </div>
                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCourierService2;