import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/exhibition-and-transportation-setup.jpg');

const aboutCourierService1 = [
    {
        titlePart1: "Transport Your Exhibition Materials",
        titlePart2: "with Confidence",
        image: require('.././../../images/9.jpg'),
      
        description: "At M3 Couriers, we understand the importance of timely and secure transportation for your exhibition materials. Whether you're showcasing your latest products or participating in a trade show, our dedicated team is committed to ensuring your belongings arrive at your destination in pristine condition. With our extensive experience in exhibition logistics, we offer a comprehensive range of services tailored to meet your specific needs. From delicate equipment to large-scale displays, our team possesses the expertise to handle all exhibition materials carefully. We prioritise the safety and security of your belongings throughout the transportation process. Our fleet of well-maintained vehicles is equipped with advanced tracking systems, allowing us to monitor the location and condition of your shipment in real time."
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "Your Exhibition, Our Priority:",
        titlePart2: "Reliable Transport and Set Up",
        image: require('.././../../images/10.jpg'),
       
        description: "When it comes to the success of your exhibition, every detail matters. From the moment your materials leave your facility to the final touches on your display, We are dedicated to providing a seamless and stress-free experience. Our team of experienced professionals understands the unique challenges associated with exhibition logistics. We work closely with you to develop a customised transportation and set-up plan that aligns with your requirements and timeline. Whether you need assistance with international shipping, on-site storage, or custom packaging, we have the expertise to handle everything. By entrusting your exhibition logistics to M3 Couriers, you can rest assured that your materials will be handled with care and professionalism. We are committed to delivering exceptional service and exceeding your expectations."
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Expert Set Up,",
        titlePart2: "Tailored to Your Needs",
        image: require('.././../../images/11.jpg'),
        
        description: "A well-organised and visually appealing exhibition booth is essential for making a lasting impression on potential customers. At M3 Couriers, we offer expert set-up services to ensure your display is assembled flawlessly and on time. Our skilled technicians have extensive experience setting up various exhibition booths, from small and portable displays to large-scale structures. We work closely with you to understand your specific design preferences and provide tailored solutions to meet your needs. We utilise the latest tools and techniques to ensure a precise and efficient set-up process. Our team is committed to maintaining high quality and attention to detail, ensuring your both looks its best. "
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Expertise and Experience::",
        description: "Our team has years of experience handling exhibition logistics, ensuring that your materials are transported and set up with the utmost care and professionalism."
    },
    {
        title: "Tailored Solutions:",
        description: "We understand that each exhibition is unique. We offer customised solutions for your needs, from international shipping to on-site storage and custom packaging."
    },
    {
        title: "Reliability and Efficiency:",
        description: "Count on M3 Couriers for timely and efficient transportation. Our dedicated team and advanced tracking systems ensure that your materials arrive at your destination on schedule."
    },
    {
        title: "Safety and Security:",
        description: "Our top priority is the safety of your exhibition materials. We implement stringent measures to protect your belongings throughout the transportation process, including insurance options for added peace of mind."
    },
    {
        title: "Comprehensive Services:",
        description: "From transportation and set-up to dismantling and return shipping, M3 Couriers provides a complete logistics solution, saving you time and effort."
    },
    {
        title: "Competitive Pricing:",
        description: "We offer competitive rates without compromising on quality. Contact us today for a personalised quote and discover the value M3 Couriers can bring to your exhibition."
    }
];
class ExhibitionTransportAndSetup extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title=" Exhibition Transport & Set up" pagename="Exhibition Transport & Set up" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    
                    <OurWorkingProcess />
                    <WhyChooseUs title= "Exhibition Transport & Set Up?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                                  
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default ExhibitionTransportAndSetup;