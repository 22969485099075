import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/bg.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">{this.props.title1}</h3>
                                         
                                            <p>At M3 Couriers, we understand the significance of timely and effective deliveries. Therefore, we are dedicated to providing an efficient, dependable, and protected same-day courier service that meets your expectations. Our team of skilled experts and modern facilities guarantee your products' careful handling and fast delivery.

By using M3 Couriers, you may have confidence that your deliveries are securely handled, providing peace of mind. Our stringent protocols for handling and our advanced tracking system provide complete transparency about your shipments' status. Suppose you want prompt delivery of important papers, samples, or components. In that case, we possess the necessary knowledge and skills to manage these tasks effectively.

</p>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                  
                                    <div className="col-md-6 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">M3 Couriers: Delivering Results, Every Time                                            </h3>
                                            <p>At M3 Couriers, we know how important it is to make packages on time and regularly. We aim to provide excellent same-day delivery services that go above and beyond what you expect. We always get results because we have a network of experienced drivers, the latest tracking technology, and a dedication to ensuring our customers are happy. Whether you need urgent papers, samples, or parts sent anywhere in the UK, our team is ready to handle your package carefully. To give you peace of mind, we offer a range of choices, reasonable prices, and real-time tracking. When you need same-day delivery that is quick, easy, and safe, M3 Couriers is the company to call.
</p>
                                           
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/same-day-excellence-service.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;