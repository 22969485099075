import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Same Day Courier`,
        link: '/same-day-courier',
        image: require('./../../images/services/same-day-courier.png'),
        description: 'Our Same Day Courier service is your solution. We understand that time is of the essence, and we are committed to ensuring your items reach their destination swiftly and securely.',
    },
    {
        count: 2,
        title: 'Medical Sector Transport',
        link: '/medical-sector-transport',
        image: require('./../../images/services/Medical-Sector-Transport.jpg'),
        description: 'M3 Couriers specialises in the safe and timely transport of medical supplies and equipment. Our dedicated medical transport team is trained to handle critical shipments with the utmost care.',
    },
    {
        count: 3,
        title: 'Overnight Courier',
        link: '/overnight-courier',
        image: require('./../../images/services/overnight-courier.jpg'),
        description: 'For shipments that require next-day delivery, our Overnight Courier service is the perfect choice. We offer efficient and dependable overnight delivery solutions to meet your business needs.',
    },
    {
        count: 4,
        title: 'Exhibition Transport & Set Up',
        link: '/exhibition-transport-set-up',
        image: require('./../../images/services/Exhibition-Transport.jpeg'),
        description: 'M3 Couriers provides comprehensive exhibition transport and setup services. We handle everything from packing and loading to on-site delivery and installation with Exhibition Transport & Set Up.',
    },
    {
        count: 5,
        title: 'Regular Routes',
        link: '/regular-routes',
        image: require('./../../images/services/route-courier-service.webp'),
        description: 'We offer customised solutions for businesses with consistent shipping patterns. Our dedicated routes and experienced drivers ensure efficient and cost-effective deliveries.',
    },
    {
        count: 6,
        title: 'Pharmaceutical',
        link: '/pharmaceutical',
        image: require('./../../images/services/Pharmaceutical-courier-service.jpg'),
        description: 'We committed to the safe and secure transportation of pharmaceutical products. Our temperature-controlled vehicles and trained personnel guarantee the integrity of your cargo.',
    }
]

var img1 = require('./../../images/background/bg-6.png');

class OurServices extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('/assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding  p-b50  square_shape2">
                    <div className="section-content">
                        <div className="Service-half-top p-t80  bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-white text-center">
                                    <div className="mt-separator-outer ">
                                        <div className="mt-separator">
                                            <h2 className="text-white text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Services</h2>
                                        </div>
                                    </div>
                                    <h3>Swift, Specialised, and Reliable Courier Services
                                    Meeting Your Business Needs with Precision and Care</h3>
                                </div>
                                {/* TITLE END */}
                            </div>
                        </div>
                
                    </div>

                  
                  
                </div>

                <div className="section-full bg-white slider-half-part">
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-md-4 col-sm-4 m-b30" key={index}>
                                        <div className="half-blocks">
                                            <div className="mt-icon-box-wraper center m-b30">
                                                <div className="half-block-content icon-count-2 p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                   
                                                    <div className="icon-content pt-110">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                        <NavLink to={`${item.link}`} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                    </div>
                                                </div>
                                                <div className="bg-cover  half-blocks-bg" style={{ backgroundImage: 'url(' + item.image + ')' }} />
                                            </div>
                                        </div>
                                    </div>
                                        
                                    ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Services</strong>
                    </div>
            </>
        );
    }
};

export default OurServices;