import React, { useState, useEffect , useRef} from 'react';
import { useForm } from '@formspree/react';

// Import images
const img1 = require('./../../images/left-men.png');
const img2 = require('./../../images/background/bg-7.jpg');
const img3 = require('./../../images/background/bg-site.png');
const img4 = require('./../../images/mission2.jpg');

const OurMission = () => {
    const [state, handleSubmit] = useForm("mpwagwoy");
    const [formMessage, setFormMessage] = useState('');
    const formRef = useRef(null); // To reference the form fields

    // A flag to track form submissions.
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (state.succeeded && !isSubmitted) {
            setFormMessage('Form Submitted!');
            setIsSubmitted(true);

            // Clear form fields after submission
            if (formRef.current) {
                formRef.current.reset(); // Reset all fields in the form
            }

            // Reset the form state after showing the message.
            const resetTimer = setTimeout(() => {
                setIsSubmitted(false); // Reset form submission flag.
                setFormMessage(''); // Clear the message after a while.
            }, 3000); // Reset the form submission flag after 3 seconds.

            // Clear the timer if component unmounts or re-renders.
            return () => clearTimeout(resetTimer);
        }
    }, [state.succeeded, isSubmitted]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormMessage(''); // Reset message on each submit attempt.
        setIsSubmitted(false); // Reset submission flag before a new submission.
        await handleSubmit(e); // Handle the form submission.
    };


    return (
        <>
            <div className="section-full mobile-page-padding mission-outer-section p-t80 p-b30 bg-gray bg-no-repeat bg-right-center" style={{ backgroundImage: `url(${img2})` }}>
                <div className="section-content">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Areas</span> We Cover</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="mission-left bg-white m-b30 p-a30 bg-no-repeat bg-bottom-left" style={{ backgroundImage: `url(${img3})` }}>
                                    <h3 className="m-t0"><span className="font-weight-100"> Site Areas</span><br /> what we serve</h3>
                                    <p>M3 Couriers provides extensive service across Southampton. We provide advanced courier services for a transportation business that is always growing and changing. We know that transportation is always changing, so we've built a strong network to meet your delivery needs. Our service goes all the way to your door, no matter where you live—in the middle of a busy city or in a peaceful town.</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="mission-mid bg-no-repeat bg-cover m-b30" style={{ backgroundImage: `url(${img4})` }} />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="contact-home1-left bg-dark p-a30 m-b0">
                                    <h3 className="text-white m-t0"><span className="font-weight-100">Get A</span> Quote</h3>
                                    <form className="cons-contact-form2 form-transparent" method="post"   onSubmit={handleFormSubmit}
                                        ref={formRef} >
                                        <div className="input input-animate">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" name="username" id="name" required />
                                            <span className="spin" />
                                        </div>
                                        <div className="input input-animate">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" name="email" id="email" required />
                                            <span className="spin" />
                                        </div>
                                        <div className="input input-animate">
                                            <label htmlFor="Phone">Phone</label>
                                            <input name="phone" type="tel" pattern="\d*" className="form-control" required/>
                                            <span className="spin" />
                                        </div>
                                        <div className="input input-animate">
                                            <label htmlFor="message">Textarea</label>
                                            <textarea name="message" id="message" required defaultValue={""} />
                                            <span className="spin" />
                                        </div>
                                        <div className="text-center p-t10">
                                            <button type="submit" disabled={state.submitting} className="site-button btn-effect">
                                                Submit Now
                                            </button>
                                        </div>
                                    </form>
                                   
                                    {formMessage ? <p className='home-form-label-color'>{formMessage}</p> : <p>&nbsp;</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                    <strong>Areas</strong>
                </div>
            </div>
        </>
    );
};

export default OurMission;
