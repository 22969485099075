import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/overnight-courier.webp');

const aboutCourierService1 = [
    {
        titlePart1: "Overnight Courier Services:",
        titlePart2: "Your Packages, Our Priority",
      
        description: "At M3 Couriers, we understand that time is of the essence. We offer reliable and efficient overnight courier services to meet your urgent shipping needs. Our commitment to excellence ensures that your packages arrive on time, every time. With our extensive network and state-of-the-art tracking systems, you can rest assured that your packages are safe. Our experienced team of professionals is dedicated to providing exceptional customer service, ensuring a smooth and hassle-free shipping experience. Whether you need to send important documents, fragile items, or large parcels, M3 Couriers has the solution. Our overnight delivery services offer flexibility and convenience for domestic and international shipments."
        ,
        image: require('.././../../images/overnight-courier-about-image1.jpg')
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "Overnight Shipping:",
        titlePart2: "Save Time with Our Efficient Services",
      
        description: "In the rapidly evolving landscape of today's world, time has become an increasingly valuable asset. At M3 Couriers, we deeply understand the critical importance of time, especially when it comes to the delivery of packages. That's why we offer specialised overnight shipping services tailored to meet the demands of those who need quick, efficient solutions. Our expansive network and meticulously optimised processes guarantee that your shipments arrive on time, every time. To achieve this, we employ a dedicated team of professionals who are committed to excellence. They work around the clock to ensure that your delivery deadlines are not just met, but exceeded. By choosing M3 Couriers, you're not just sending a package; you're sending trust, reliability, and peace of mind, knowing that we handle each shipment with the utmost care and urgency."
        ,image: require('.././../../images/overnight-courier-about-image2.jpg')
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Overnight Delivery:",
        titlePart2: "Experience the Speed and Convenience",
      
        description: "When you require swift and dependable package delivery, M3 Couriers is your go-to solution. Our overnight delivery services are designed to provide the expedience and reliability essential for meeting your urgent shipping needs. Utilising state-of-the-art tracking technology, we enable you to monitor the progress of your consignment in real time, ensuring peace of mind throughout the delivery process. Additionally, our dedicated customer support team is always on hand to assist with any queries or concerns you may have regarding your shipment. Whether it's important business documents or last-minute gifts, our services are tailored to ensure your packages arrive on time, every time. Don’t hesitate—contact M3 Couriers today to arrange your overnight delivery and discover the efficiency and ease of our premium services."
        ,image: require('.././../../images/overnight-courier-about-image3.jpg')
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Speed and Efficiency:",
        description: "We prioritize timely delivery, ensuring your packages arrive on time every time. Our efficient network and streamlined processes guarantee prompt service."
    },
    {
        title: "Reliability and Trust:",
        description: "M3 Couriers is committed to delivering your packages with the utmost care. Our experienced team and advanced tracking systems ensure that your shipments are handled safely and securely."
    },
    {
        title: "Global Reach:",
        description: "We offer domestic and international overnight courier services, connecting you to businesses and individuals worldwide. Our extensive network ensures your packages reach their destination efficiently."
    },
    {
        title: "Convenience and Flexibility:",
        description: "M3 Couriers provides a hassle-free shipping experience. Our online tools and dedicated customer support make scheduling, tracking, and managing your shipments easy."
    },
    {
        title: "Experienced Team:",
        description: "Our professionals have years of experience in the shipping industry."
    },
    {
        title: "Competitive Pricing:",
        description: "We offer competitive rates without compromising on quality. Our affordable pricing makes M3 Couriers cost-effective for your overnight courier needs."
    }
];
class OvernightCourier extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title="Overnight Courier" pagename="Overnight Courier" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    
                    <OurWorkingProcess />
                    <WhyChooseUs title= "Overnight Courier?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                                  
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default OvernightCourier;