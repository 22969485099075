import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import About4 from './../Elements/About4';

import Team3 from './../Elements/Team3';
import AboutSummary from './../Elements/AboutSummary';
import OurValue from '../Elements/OurValue';
import OurWorkingProcess from '../Elements/OurWorkingProcess';

var bnrimg = require('./../../images/banner/about-us-bg.webp');

class About extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="About Us" pagename="About Us" bgimage={bnrimg} />
                    <AboutSummary />
                    <OurValue />
                 
                    <OurWorkingProcess />
                   

                </div>

                <Footer />

            </>
        );
    };
};

export default About;