import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Contact Us`,
        icon: require('./../../images/icon/call-us-icon.png'),
        description: "Do you need assistance with your shipment or have questions about our services? Contact us anytime. We’re here to help.",
    },
    {
        count: 2,
        title: 'Package Pickup',
        icon: require('./../../images/icon/package-pickup.png'),
        description: 'Enjoy hassle-free package pickup from your doorstep or preferred location. Let us take care of collecting your shipments.',
    },
    {
        count: 3,
        title: 'Secure Delivery',
        icon: require('./../../images/icon/secure-safe-delivery.webp'),
        description: "Your packages are in safe hands. We prioritise the secure and timely delivery of your shipments to their destinations.",
    },
    {
        count: 4,
        title: 'Happy Client',
        icon: require('./../../images/icon/happy-client.png'),
        description: 'Our satisfied customers speak for our quality service. Join the growing list of clients who trust M3 Couriers for their shipping needs.',
    }
]

var img1 = require('./../../images/background/bg-5.png');

class OurWorkingProcess extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-tb80 bg-white bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Working Process</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                    {services.map((item, index) => (
                                        <div className="col-md-3 col-sm-6" key={index}>
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurWorkingProcess;