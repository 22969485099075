import React from 'react';
import Header from '.././../Common/Header';
import Footer from '.././../Common/Footer';
import Banner from '.././../Elements/Banner';
import AboutCourierService from '../../Elements/AboutCourierService';
import AboutCourierService2 from '../../Elements/AboutCourierService2';
import ServiceBenefits from '../.././Elements/ServiceBenefits';
import OurWorkingProcess from '../../Elements/OurWorkingProcess';
import WhyChooseUs from '../../Elements/WhyChooseUs';
import AboutCourierService3 from '../../Elements/AboutCourierService3';


var bnrimg = require('.././../../images/banner/regular-routes.jpg');

const aboutCourierService1 = [
    {
        titlePart1: "Your Packages, Our Priority:",
        titlePart2: "Explore Our Regular Routes",
       
        image: require('.././../../images/12.jpg'),
        description: "At M3 Couriers, we understand the importance of timely and reliable package delivery. Our extensive network of regular routes ensures that your shipments reach their destination efficiently and securely. Whether you're shipping documents, goods, or parcels, our regular routes offer a convenient and cost-effective solution. We have established routes connecting major cities and regions, providing consistent service and predictable delivery times. Our team of experienced professionals is dedicated to providing exceptional customer service. We offer flexible shipping options to accommodate your needs, including scheduled pick-ups and tracking services for real-time updates. Experience the M3 Couriers difference. "
    },
   
];
const aboutCourierService2 = [
    {
        titlePart1: "Regular Routes:",
        titlePart2: "M3 Couriers' Reliable Delivery Network",
       
        image: require('.././../../images/13.jpg'),
        description: "M3 Couriers is proud to offer a comprehensive network of regular routes, connecting businesses and individuals nationwide. Our commitment to reliability and efficiency ensures that your packages arrive on time, every time. Our regular routes are carefully planned to optimise delivery times and minimise transit distances. We invest in state-of-the-art technology and a well-maintained fleet of vehicles to ensure your shipments' safe and timely transportation. We understand that consistency is crucial for businesses. Our regular routes provide predictable delivery schedules, allowing you to plan your operations confidently. Additionally, our experienced professionals are always available to assist you and answer any questions. Trust M3 Couriers for your regular shipping needs."
    },
   
];
const aboutCourierService3 = [
    {
        titlePart1: "Fast and Efficient Deliveries",
        titlePart2: "Across Major Cities",
     
        image: require('.././../../images/regular-routes-about-image3.jpg'),
        description: "M3 Couriers is committed to delivering your packages quickly and efficiently to major cities nationwide. Our extensive network of regular routes ensures that your shipments reach their destination promptly. We understand the importance of speed in today's fast-paced business environment. Our team is dedicated to optimising delivery routes and minimising transit times. With M3 Couriers, you can rely on fast, reliable delivery services that meet your tight deadlines. Our regular routes are designed to provide consistent service, allowing you to plan your shipments confidently. Additionally, our tracking services offer real-time updates so you can monitor the progress of your packages."
    },
   
];
const whyChooseUsPoints = [
    {
        title: "Extensive Network:",
        description: " Our extensive network of regular routes ensures your packages reach their destination efficiently and reliably."
    },
    {
        title: "Reliability and Efficiency:",
        description: "Count on M3 Couriers for timely and consistent deliveries. Our team is dedicated to optimising routes and minimising transit times."
    },
    {
        title: "Competitive Rates:",
        description: "Enjoy competitive pricing without compromising on quality."
    },
    {
        title: "Tracking and Notifications:",
        description: "Stay informed with real-time monitoring and notifications for your shipments."
    },
    {
        title: "Experienced Team:",
        description: "Our professionals have years of experience in the shipping industry."
    },
    {
        title: "Customer Satisfaction:",
        description: "We prioritise customer satisfaction and strive to exceed your expectations."
    }
];
class RegularRoutes extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner  title="Regular Routes" pagename="Regular Routes" bgimage={bnrimg}/>
                    <AboutCourierService aboutservice1 =  {aboutCourierService1}  />
                    <AboutCourierService2 aboutservice2 =  {aboutCourierService2}  />
                    
                    <OurWorkingProcess />
                    <WhyChooseUs title= "Your Shipping Needs?" points={whyChooseUsPoints} />
                    <AboutCourierService3 aboutservice3= {aboutCourierService3}  />                                  
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default RegularRoutes;